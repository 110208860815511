import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "flex justify-center items-center gap-1.5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_checkbox = _resolveComponent("base-checkbox")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_base_badge = _resolveComponent("base-badge")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_add_to_board_modal = _resolveComponent("add-to-board-modal")!
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_filter_modal_content = _resolveComponent("filter-modal-content")!
  const _component_base_button_primary = _resolveComponent("base-button-primary")!
  const _component_teleport_to_content_footer = _resolveComponent("teleport-to-content-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "9",
      loading: _ctx.loading,
      onLoadMore: _ctx.loadMore,
      "has-more": _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, {
              class: "w-0 pr-0",
              type: "label"
            }),
            _createVNode(_component_base_table_column, { type: "label" }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.crew_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.email")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              "text-align": "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.length_of_work")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, {
              type: "label",
              "text-align": "center"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.status")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (
          _ctx.useRouter().push({
            name: _ctx.RouteNames.CrewDetail,
            params: {
              id: row.id,
            },
          })
        ), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, { class: "w-0 pr-0" }, {
                default: _withCtx(() => [
                  (row.status === _ctx.CrewStatus.StandBy)
                    ? (_openBlock(), _createBlock(_component_base_checkbox, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                        value: row,
                        modelValue: _ctx.selectedCrews,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCrews) = $event))
                      }, null, 8, ["value", "modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "pr-0 w-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_avatar_img, {
                    src: row.avatar_link,
                    alt: `${row.email}_avatar`
                  }, null, 8, ["src", "alt"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.fullname), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.crew_id), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.email), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.position), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { "text-align": "center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.contract_on_duration) + " " + _toDisplayString(_ctx.trans(`layout.selection.${row.contract_on_type}`)), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_base_badge, {
                      color: _ctx.badgeColor(row.status)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("i", {
                            class: _normalizeClass(["text-base", _ctx.badgeIconClass(row.status)])
                          }, null, 2),
                          _createTextVNode(" " + _toDisplayString(_ctx.makeFirstCapital(_ctx.trans(`layout.crew.${row.status}`))), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["loading", "onLoadMore", "has-more", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_add_to_board_modal, {
      crews: _ctx.selectedCrewStandby,
      onDone: _ctx.onAddToBoardDone,
      modelValue: _ctx.showAddToBoardModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAddToBoardModal) = $event))
    }, null, 8, ["crews", "onDone", "modelValue"]),
    _createVNode(_component_filter_modal_content, {
      title: _ctx.trans('layout.filter_crew'),
      filter: _ctx.filter,
      "onUpdate:filter": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filter) = $event)),
      submittedFilter: _ctx.submittedFilter,
      "onUpdate:submittedFilter": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.submittedFilter) = $event)),
      onSubmit: _cache[8] || (_cache[8] = ($event: any) => (_ctx.reload())),
      onReset: _cache[9] || (_cache[9] = ($event: any) => (_ctx.resetAll(true))),
      onHide: _cache[10] || (_cache[10] = ($event: any) => (_ctx.resetAll()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.crew.crew_id'),
          inputType: "text",
          modelValue: _ctx.filter.crew_id,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.crew_id) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.job.position_id'),
          items: _ctx.selectionDataToBaseItem(_ctx.selectionStore.state.job_position),
          inputType: "multi-select",
          modelValue: _ctx.filter.position_in,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filter.position_in) = $event)),
          ref: "formGroupPosition"
        }, null, 8, ["label", "items", "modelValue"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.crew.status'),
          inputType: "select",
          modelValue: _ctx.filter.status,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filter.status) = $event)),
          items: [
        {
          value: _ctx.CrewStatus.OnBoard,
          text: _ctx.trans(`layout.crew.${_ctx.CrewStatus.OnBoard}`),
        },
        {
          value: _ctx.CrewStatus.StandBy,
          text: _ctx.trans(`layout.crew.${_ctx.CrewStatus.StandBy}`),
        },
      ]
        }, null, 8, ["label", "modelValue", "items"])
      ]),
      _: 1
    }, 8, ["title", "filter", "submittedFilter"]),
    _createVNode(_component_teleport_to_content_footer, {
      withCancelButton: false,
      withSaveButton: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_button_primary, {
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showAddToBoardModal = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.trans("layout.add_to_board")) + " (" + _toDisplayString(_ctx.selectedCrewStandby.length) + ")", 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}