
import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseMessageError from "@/components/Base/BaseMessageError.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import crewHttp from "@/http/crew";
import fleetHttp from "@/http/fleet";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {
    BaseModal,
    BaseFormGroup,
    BaseButtonDanger,
    BaseButtonPrimary,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    CompanyLogo,
    AvatarImg,
    BaseMessageError,
  },
  mixins: [helperMixin, baseModal],
  emits: ["done"],
  props: {
    state: {
      type: String as PropType<"new schedule" | "reschedule">,
      default: "new schedule",
    },
    crews: {
      type: Array as PropType<
        ({
          sign_on: string;
          sign_off: string;
        } & import("@/models/crew").CrewPaginate)[]
      >,
      required: true,
    },
  },
  computed: {
    crewHttp,
    fleetHttp,
  },
  data() {
    return {
      fleetId: "",
    };
  },
  methods: {
    onModalHide() {
      this.validationErrors = {};
    },
    async addToBoard() {
      if (
        await this.requestHandler(
          this.crewHttp.addToBoard({
            fleet_id: this.fleetId,
            crews: this.crews.map((data) => ({
              id: data.id,
              sign_on: data.sign_on ?? "",
              sign_off: data.sign_off ?? "",
            })),
          })
        )
      ) {
        this.value = false;
        this.$emit("done");
      }
    },
  },
});
